<template>
  <div class="us-container">
    <!--    <iframe style="width: 100%;height: 100%;" src=" https://contracts.xuweidj.com/cyhweb/contactus/zh_cn.html"></iframe>-->
    <div class="top">
      <el-button class="close" plain @click="$router.push('/')">关闭</el-button>
      <h4>ZY DJ WEB 联系我们</h4>
    </div>
    <div class="main">
      <pre
        style="
          font-family: 'SF Pro Text', 'Helvetica Neue', Helvetica, Arial,
            'PingFang SC', 'Microsoft YaHei', 微软雅黑, 'sans-serif';
          font-size: 14px;
          white-space: pre-wrap;
          overflow-wrap: break-word;
        "
      >
如有任何问题，您可通过电子邮件（dev@chaodj.com.cn）与我们联系；</pre
      >
      <p>
        <br />
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      d: {},
    };
  },
  mounted() {
    // this.$api.sendGetUS().then((resolve) => {
    //   console.log(resolve);
    //   this.d = resolve.data.records[0];
    // });
  },
};
</script>
<style scoped lang="scss">
@import "./index.css";
.us-container {
  .main {
    margin: auto;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > p {
      margin-top: 25px;
      width: 300px;
    }
  }
}
</style>
